import React, { memo } from 'react'
import DSELayout from '../components/DSE/DSELayout/DSELayout';
import DseHero from '../components/DSE/DSEhero/DSEhero';


/* As you probably know, Gatsby makes it very easy to route the project.  You simply need to add the .js or .jsx file To the pages directory and then the name of the page becomes the route.  However, in order to organize the project Properly, you would typically put the .jsx file and the accompanying .scss file into it's own directory.  Doing That for the pages, would create a double directory for the route.  For example, /home/home.  As a result, For organization purposes, I have placed the pages here and simply returned the accompanying, 'Content' jsx. The content jsx and scss are organized as they're supposed to be, within there own directory and that is theWhere the content for each page really begins.
 */

const Home = props => {
  return (
    <React.Fragment>
      <DSELayout
        canonicalURL='https://npcfacts.com/'
        title='Nasopharyngeal Cancer (NPC) Facts | Home Page'
        pageTitle='Nasopharyngeal Cancer (NPC) Facts | Home Page'
        description='Nasopharyngeal cancer (NPC): Get helpful information and support'
        keyWords='Nasopharyngeal carcinoma; NPC treatments; NPC outcomes'
        ogType='Website'
        ogTitle='Nasopharyngeal Cancer (NPC) Facts | Understanding more about this rare cancer'
        ogDescription= 'Learn about nasopharyngeal cancer and the options you may have for treatment'
        ogImage='/images/NPC_facts_logo.png'
        >
       <DseHero />
      </DSELayout>
    </React.Fragment>
  )
}

export default memo(Home)
