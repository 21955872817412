import React from 'react';
import DseCta from "../DSECta/DSECta";
import { navigate } from "gatsby";
import DseCtaIcon from "../DseCtaIcon/DseCtaIcon";
import DSEWrapper from '../DSEWrapper/DSEWrapper'

const DseHero = ({ className }) => {
  const clickHandler = () => {
    navigate("/join-NPCFacts-community");
  };

  return (
    <DSEWrapper fullWidth={true}>
      <div className={`dse-hero ${className || ""}`}>
        <div className="dse-hero__content">
          <h1 className="dse-main-title dse-hero__title">
            <span className="dse-main-title__highlight">Understanding</span>
            <br />
            nasopharyngeal
            <br />
            cancer
          </h1>
          <div className="dse-hero__text">
            <p>
              A diagnosis of nasopharyngeal cancer,
              sometimes called NPC, can be overwhelming.
            </p>
            <br />
            <p>
              The information on this site
              may help you better
              understand the disease and
              the options you may have
              for treating it.
            </p>
          </div>
          <DseCtaIcon
            text="Begin here by reading more <br> about nasopharyngeal cancer"
            href="/about-nasopharyngeal-cancer/"
          />
          <p className="dse-main-color">
            Get educational tools and resources to help support you on your NPC
            journey</p>
          <DseCta
            className="dse-hero__cta"
            text="JOIN THE NPC FACTS COMMUNITY"
            action={clickHandler}
          />
        </div>
      </div>
    </DSEWrapper>
  );
};

export default DseHero;
